<template>
	<section class="permission">
		<!--列表-->
		<el-table :data="listData" highlight-current-row v-loading="listLoading" style="width: 100%;">
			<el-table-column prop="role_name" label="id" width="120">
			</el-table-column>
			<el-table-column prop="role_name" label="修改前状态" width="200">
			</el-table-column>
			<el-table-column prop="role_name" label="修改后状态" width="200">
			</el-table-column>
			<el-table-column prop="create_time" label="修改时间" sortable width="200">
			</el-table-column>
			<el-table-column prop="role_name" label="操作人" width="200">
			</el-table-column>	
		</el-table>

		<!--工具条-->
		<el-col :span="24" class="toolbar">
			<!--分页-->
			<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="page"
					:page-sizes="pagesizes"
					:page-size="pagesize"
					layout="total, sizes, prev, pager, next, jumper"
					:total="total">
			</el-pagination>
		</el-col>

	</section>
</template>

<script>
	import { getAdminList, stateAdmin} from '../../api/api';
	export default {
		data() {
			return {
				filters: {
					keyword: ''
				},
				listData: [], //管理员列表数据
				//分页
				total: 0,
				page: 1,
				pagesize: 10,//每页条数
				pagesizes: [10, 20, 50, 100],//可选条数
				listLoading: false,
				sels: [],//列表选中列
			}
		},
		methods: {
			//分页
			handleSizeChange(val) {
				this.pagesize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			//获取用户列表
			getList(val) {
                if(val == '0') this.page = 1
				let para = {
					page: this.page,
					keyword: this.filters.keyword,
					pagesize: this.pagesize	//分页条数
				};
				this.listLoading = true;
				getAdminList(para).then((res) => {
					if(res.code == 1){
						this.total = res.data.count;
						this.listData = res.data.data;
						this.listLoading = false;
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			//恢复、冻结、删除
			handleState: function (index, row, text) {
				this.$confirm('确认'+text+'该用户吗?', '提示', {
					type: 'warning'
				}).then(() => {
					this.listLoading = true;
					var astatus = function(){
						return text == '冻结' ? 2 : text == '恢复' ? 1 : text == '批量冻结' ? 2 : text == '批量恢复' ? 1 : '0';
					}
					//判断是否批量
					var ids = this.sels.map(item => item.id).toString();
					var para = {
						id: ids,
						status: astatus()
					};
					stateAdmin(para).then((res) => {
						this.listLoading = false;
						if(res.code==1){
							this.$message({
								message: text+'成功',
								type: 'success'
							});
							this.getList();
						}else {
							this.$message({
								message: res.msg,
								type: 'error'
							});
						}
					});
				}).catch(() => {

				});
			},
			//显示编辑界面
			handleEdit: function (index, row) {
				this.$router.push({ name: '创建店铺账号', query: { id: row.id }})
			},
			//显示新增界面
			handleAdd: function () {
				console.log("创建店铺账号")
				this.$router.push({ name: '创建店铺账号'})
			},
		},
		mounted() {
			//获取列表数据
			//this.getList();
		}
	}

</script>

<style lang="scss">
	@import "../../styles/permission.scss";
</style>